import uppercase from './uppercaseFilter'
import filesize from './fileSizeFilter'
import currency from './moneyFilter'
import sub from './subFilter'

export default {
  install(Vue) {
    Vue.filter('uppercase', uppercase);
    Vue.filter('filesize', filesize);
    Vue.filter('currency', currency);
    Vue.filter('sub', sub);
  }
}