import settings from '@/global'

export function authHeader(axios) {
    const user = JSON.parse(localStorage.getItem(settings.cache.user));

    if (user && user.token)
        axios.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
}

export function getAuthHeader() {
    const user = JSON.parse(localStorage.getItem(settings.cache.user));

    if (user && user.token)
        return {'Authorization': `Bearer ${user.token}`};
    return {};
}
