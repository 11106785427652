import settings from '@/global'

export function authChecker(to, from, next) {
    const publicPages = ['/login'];
    const autRequired = !publicPages.includes(to.path);
    const user = JSON.parse(localStorage.getItem(settings.cache.user));
    const loggedIn = user && user.generated && new Date(user.generated).getTime() + (2 * 60 * 60 * 1000) > new Date().getTime();
    //console.log(`authorize - auth:${autRequired}  logged:${loggedIn}`);

    if (autRequired && !loggedIn)
        next('/login');
    else {
        if(user) {
            //console.log(`logged user: ${user.email}`);
            user.generated = new Date();
            localStorage.setItem(settings.cache.user, JSON.stringify(user));
            //console.log("auth updated");
            //console.log(to.path);
            //console.log(window.location.pathname);
            //console.log(user.roles);
            // activate change password forced
            //console.log(`change: ${user.changePassword}`);
            if(user.changePassword && window.location.pathname != '/admin.html') {
                //console.log('ChangePassword');
                window.location = "/admin.html";
            }
        }
        const hasMeta = to.meta;
        const isProto = Object.getPrototypeOf(hasMeta) == Object.prototype;
        const hasAuthorize = Object.keys(hasMeta).includes("authorize");
        const authorize = hasMeta && isProto && hasAuthorize ? hasMeta.authorize : null;
        const isArray = Array.isArray(authorize);
        const hasRole = authorize && isArray ? user.roles.some(r => authorize.includes(r.name)) : false;

        //console.log(authorize);
        //if(authorize && isArray) console.log(user.roles.some(r => authorize.includes(r.name)))
        
        if(authorize && !hasRole) {
            console.log("not include");
            next('/login');
        }
        
        next();
    }
}