<style>
/* login style input */
.prepend {
    position: relative;
}

.prepend input {
    width: 100%;
    height: 32px;
    border: none;
    border-bottom: 1px solid #888;
}

.prepend input {
    text-indent: 38px;
}

.prepend svg {
    position: absolute;
    -webkit-top: 12px;
    -moz-top: 8px;
    top: 12px;
    left: 10px;
}

/* selectors */
.selected {
    background-color: white;
    border-radius: 6px;
    border: 1px solid #ccc;
    color: gray;
    padding-left: 8px;
    cursor: pointer;
    user-select: none;
    height: 36px;
    overflow: hidden;
}

/*
.selected.open{
    border: 1px solid silver;
    border-radius: 6px 6px 0px 0px;
}
*/
.selected:after {
    position: absolute;
    content: "";
    top: 18px;
    right: 10px;
    width: 0;
    height: 0;
    border: 4px solid transparent;
    border-color: #333 transparent transparent transparent;
}

.search {
    float: right;
    margin-right: 20px;
    margin-top: 3px;
}

.search input[type=text] {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid silver;
}

.items {
    color: gray;
    border-radius: 6px;
    overflow: auto;
    max-height: 300px;
    border: 1px solid silver;
    position: absolute;
    background-color: white;
    margin-top: 5px;
    left: 0;
    right: 0;
    z-index: 1000;
}

.item {
    color: black;
    padding-left: 8px;
    cursor: pointer;
    user-select: none;
    border-bottom: 1px solid silver;
}

.item small {
    color: #666;
    font-size: small;
}

.item:hover {
    color: #ffffff;
    background-color: #40bfff;
}

.item:hover small {
    color: #ccc;
}

.selectHide {
    display: none;
}

/* round button */
.btn-circle.btn-xl {
    width: 70px;
    height: 70px;
    padding: 10px 16px;
    border-radius: 35px;
    font-size: 24px;
    line-height: 1.33;
}

.btn-circle.btn-xs {
    width: 20px;
    height: 20px;
    padding: 1px 0px;
    border-radius: 10px;
    text-align: center;
    font-size: 8px;
    line-height: 0.6;
}

.btn-circle {
    width: 30px;
    height: 30px;
    padding: 6px 0px;
    border-radius: 15px;
    text-align: center;
    font-size: 12px;
    line-height: 1.42857;
}

.border-bottom {
    border-bottom: 1px solid grey;
}

/*  *********  */
.date-pill {
    display: inline-block;
    font-size: 11px;
    border-radius: 12px;
    color: white;
    padding: 0 6px 0 3px;
}

/* sections */
.section {
    background-color: #f2f2f6;
    color: #666;
    margin: 2px;
}

.section-alert {
    background-color: #FF8A80;
    color: #000;
    margin: 2px;
}

.section label {
    font-weight: bold;
}

.section-alert label {
    font-weight: bold;
}

.view-band {
    border-left: 5px solid #362F84;
}

.edit-band {
    border-left: 5px solid #E52878;
}

/* lane */
.lane {
    background-color: #F7F8F9;
    width: 100%;
    margin: 2px;
    padding: 2px;
}

.lane .title {
    height: 30px;
    line-height: 30px;
    color: black;
    background-color: #BCBCBC;
    text-align: center;
    vertical-align: middle;
    font-weight: 600;
    padding: 2px 0;
    margin-bottom: 4px;
}

.lane .title:before {
    float: right;
    content: '\2807';
    font-size: 20px;
    margin: 0 4px 0 8px;
    padding: 0;
    text-align: right;
}

.fab-mt {
    margin-top: -2px;
}

/* control complete */
.complete-results {
    position: absolute;
    padding: 0;
    margin: 0;
    width: 200px;
    border: 1px solid silver;
    overflow: auto;

    border-radius: 6px;
    max-height: 300px;
    background-color: white;
    margin-top: 5px;
    z-index: 1100;
}

.complete-result {
    list-style: none;
    text-align: left;
    padding: 4px 2px;
    cursor: pointer;
    border-bottom: 1px solid silver;
}

.complete-result.is-active,
.complete-result:hover {
    background-color: #40bfff;
    color: white;
}

/* botón de icono */
.btn-icon {
    color: lightgray;
}

.btn-icon:hover {
    color: black;
    transition: color 2s;
}
</style>

<template>
    <component :is="layout">
        <router-view :layout.sync="layout" />
    </component>
</template>

<script>
export default {
    name: 'App',
    data() {
        return { layout: 'div' };
    }
};
</script>