export default {
    // ttl time in minutes
    set(key, value, ttl, version) {
        const now = new Date();
        const item = { value, expiry: ttl ? now.getTime() + (ttl * 60 * 1000) : 0, version };

        localStorage.setItem(key, JSON.stringify(item));
    },

    get(key, version) {
        const itemStr = localStorage.getItem(key);

        if(!itemStr)
            return null;
        
        const item = JSON.parse(itemStr);
        const now = new Date();
        
        if(item.expiry && (now.getTime() > item.expiry)) {
            localStorage.removeItem(key);
            return null;
        }

        if(version && item.version && (version > item.version)) {
            localStorage.removeItem(key);
            return null;
        }

        return item.value;
    },

    clear(key) {
        localStorage.removeItem(key);
    }
}