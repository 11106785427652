const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import(/* webpackPrefetch: true */'@views/Request'),
        meta: { authorize: [ 'ADMINISTRATOR', 'ANALYST', 'BASE_ANALYST', 'ANALYSTBOSS' ] },
    },
    {
        path: '/request2',
        name: 'Request',
        component: () => import('@views/Request2'),
        meta: { authorize: [ 'ADMINISTRATOR', 'ANALYST', 'ANALYSTBOSS' ] },
    },
    {
        path: '/request',
        name: 'Solicitud',
        component: () => import(/* webpackPrefetch: true */'@views/RequestList'),
        meta: { authorize: [ 'ADMINISTRATOR', 'ANALYST', 'ANALYSTBOSS' ] },
    },
    {
        path: '/request/manage/:id',
        name: 'Gestionar',
        component: () => import(/* webpackPrefetch: true */'@views/RequestManage'),
        props: true,
        meta: { authorize: [ 'ADMINISTRATOR', 'ANALYST', 'ANALYSTBOSS' ] },
    },
    {
        path: '/request2/manage/:id',
        name: 'Gestionar2',
        component: () => import('@views/RequestManage2'),
        props: true,
        meta: { authorize: [ 'ADMINISTRATOR', 'ANALYST', 'ANALYSTBOSS' ] },
    },
    {
        path: '/soat/manage/:id',
        name: 'GestionarSoat',
        component: () => import('@views/SoatManage'),
        props: true,
        meta: { authorize: [ 'ADMINISTRATOR', 'SOAT', 'BASE_ANALYST', 'ANALYSTBOSS' ] },
    },
    {
        path: '/specialView',
        name: 'Especial',
        component: () => import('@views/SpecialView.vue'),
        meta: { authorize: [ 'ADMINISTRATOR', 'ANALYST', 'ANALYSTBOSS' ] },
    },
    {
        path: '/liquidation',
        name: 'Liquidation',
        component: () => import('@views/Liquidation.vue'),
        meta: { authorize: [ 'ADMINISTRATOR', 'ANALYST', 'ANALYSTBOSS' ] },
    },
    {
        path: '/operationTaskList',
        name: 'Tareas',
        component: () => import('@views/OperationTaskList'),
        meta: { authorize: [ 'ADMINISTRATOR', 'ANALYST', 'BASE_ANALYST', 'ANALYSTBOSS' ] },
    },
    {
        path: '/juridical',
        name: 'Juridico',
        component: () => import('@views/JuridicalRequestList'),
        meta: { authorize: [ 'ADMINISTRATOR', 'JURIDICAL', 'ANALYSTBOSS' ] },
    },
    {
        path: '/juridical/manage/:id',
        name: 'Gestion Juridica',
        component: () => import('@views/JuridicalManage'),
        props: true,
        meta: { authorize: [ 'ADMINISTRATOR', 'JURIDICAL', 'ANALYSTBOSS' ] },
    },
    {
        path: '/Soat',
        name: 'SOAT',
        component: () => import('@views/SoatRequestList'),
        meta: { authorize: [ 'ADMINISTRATOR', 'SOAT', 'BASE_ANALYST', 'ANALYSTBOSS' ] },
    },
    {
        path: '/soatReport',
        name: 'SOAT Reporte',
        component: () => import('@views/SoatClosedReport'),
        meta: { authorize: [ 'ADMINISTRATOR', 'SOAT', 'ANALYSTBOSS' ] },
    },
    {
        path: '/siras',
        name: 'Siras',
        component: () => import('@views/Siras'),
        meta: { authorize: [ 'ADMINISTRATOR', 'SOAT', 'ANALYSTBOSS' ] },
    },
    {
        path: '/integration',
        name: 'Integración',
        component: () => import('@views/Integration'),
        meta: { authorize: [ 'ADMINISTRATOR', 'SOAT', 'ANALYSTBOSS' ] },
    },
    {
        path: '/multi',
        name: 'Multi',
        component: () => import('@views/MultiActions'),
        meta: { authorize: [ 'ADMINISTRATOR', 'ANALYSTBOSS' ] },
    },
    {
        path: '/UbicaPlusGen',
        name: 'UbicaPlusGen',
        component: () => import('@views/UbicaPlusGen'),
        meta: { authorize: [ 'ADMINISTRATOR', 'ANALYSTBOSS' ] },
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@views/Login'),
    },
    
    //****  Analyst boss ********************************
    {
        path: '/requestList',
        name: 'RequestList',
        component: () => import('@views/abRequestList'),
        meta: { authorize: [ 'ADMINISTRATOR', 'ANALYSTBOSS' ] },
    },
    {
        path: '/weekReport',
        name: 'Reporte Semanal',
        component: () => import('@views/WeekReport'),
        meta: { authorize: [ 'ADMINISTRATOR', 'ANALYSTBOSS' ] },
    },
    {
        path: '/specialList',
        name: 'Clientes Especiales',
        component: () => import('@views/SpecialList'),
        meta: { authorize: [ 'ADMINISTRATOR', 'ANALYSTBOSS' ] },
    },
    {
        path: '/operationBillingList',
        name: 'Reporte de facturación',
        component: () => import('@views/OperationBillingList'),
        meta: { authorize: [ 'ADMINISTRATOR', 'ANALYSTBOSS' ] },
    },
    {
        path: '/accounting',
        name: 'Cuentas',
        component: () => import('@views/Accounting'),
        meta: { authorize: [ 'ADMINISTRATOR', 'ANALYSTBOSS' ] },
    },

    {
        path: '/OperationWOBillingList',
        name: 'Reporte consolidado de tareas',
        component: () => import('@views/OperationWOBillingList'),
        meta: { authorize: [ 'ADMINISTRATOR', 'ANALYSTBOSS' ] },
    },

    {
        path: '/UbicaPlus',
        name: 'Consulta en Ubica Plus',
        component: () => import('@views/UbicaPlus'),
        meta: { authorize: [ 'ADMINISTRATOR', 'ANALYSTBOSS' ] },
    },

    {
        path: '/ReverseUbicaPlus',
        name: 'Consulta reversa en Ubica Plus',
        component: () => import('@views/ReverseUbicaPlus'),
        meta: { authorize: [ 'ADMINISTRATOR', 'ANALYSTBOSS' ] },
    },
    
    //**** Billing ***************************************
    {
        path: '/billingReport',
        name: 'billingReport',
        component: () => import('@views/BillingReport'),
        meta: { authorize: [ 'ADMINISTRATOR', 'ACCOUNTING' ] },
    },

    {
        path: '/negotiation',
        name: 'Negotiation',
        component: () => import('@views/Negotiation'),//Negotiation,
        meta: { authorize: [ 'ADMINISTRATOR', 'ACCOUNTING' ] },
    },
    
    {
        path: '/cost',
        name: 'Cost',
        component: () => import('@views/Cost'),//Cost,
        meta: { authorize: [ 'ADMINISTRATOR', 'ACCOUNTING' ] },
    },

    {
        path: '/rate',
        name: 'Rate',
        component: () => import('@views/Rate'),//Rate,
        meta: { authorize: [ 'ADMINISTRATOR', 'ACCOUNTING' ] },
    },

    {
        path: '/clientPointPurse',
        name: 'ClientPointPurse',
        component: () => import('@views/ClientPointPurse.vue'),
        meta: { authorize: [ 'ADMINISTRATOR', 'ACCOUNTING' ] },
    },
    {
        path: '/otherService',
        name: 'Servicios',
        component: () => import('@views/OtherServiceList'),
        meta: { authorize: [ 'ADMINISTRATOR', 'ANALYST', 'ANALYSTBOSS' ] },
    },
    {
        path: '/otherService/manage/:id',
        name: 'DetalleServicios',
        component: () => import('@views/OtherServiceManage'),
        meta: { authorize: [ 'ADMINISTRATOR', 'ANALYST', 'ANALYSTBOSS' ] },
    },

    {
        path: '/serviceAccounting',
        name: 'ServiceAccounting',
        component: () => import('@views/ServiceAccounting.vue'),
        meta: { authorize: [ 'ADMINISTRATOR', 'ACCOUNTING' ] },
    },

    {
        path: '/service',
        name: 'Service',
        component: () => import('@views/Service.vue'),
        meta: { authorize: [ 'ADMINISTRATOR', 'ACCOUNTING' ] },
    },

    {
        path: '/accountingDate',
        name: 'AccountingDate',
        component: () => import('@views/AccountingDate'),
        meta: { authorize: [ 'ADMINISTRATOR', 'ACCOUNTING' ] },
    },

    {
        path: '/OtherWOBillingList',
        name: 'ReporteConsolidadoTareas',
        component: () => import('@views/OtherWOBillingList'),
        meta: { authorize: [ 'ADMINISTRATOR', 'ACCOUNTING' ] },
    },

    //**** GENERALS ********************************
    {
        path: '/client',
        name: 'Client',
        component: () => import(/* webpackPrefetch: true */'@views/Client.vue'),
        meta: { authorize: [ 'ADMINISTRATOR' ] },
    },
    {
        path: '/costCenter',
        name: 'CostCenter',
        component: () => import('@views/CostCenter.vue'),
        meta: { authorize: [ 'ADMINISTRATOR' ] },
    },
    {
        path: '/position',
        name: 'Position',
        component: () => import('@views/Position.vue'),
        meta: { authorize: [ 'ADMINISTRATOR' ] },
    },
    {
        path: '/provider',
        name: 'Provider',
        component: () => import('@views/Provider.vue'),
        meta: { authorize: [ 'ADMINISTRATOR' ] },
    },
    {
        path: '/user',
        name: 'User',
        component: () => import(/* webpackPrefetch: true */'@views/User.vue'),
        meta: { authorize: [ 'ADMINISTRATOR' ] },
    },
    {
        path: '/coverage',
        name: 'Coverage',
        component: () => import('@views/Coverage.vue'),
        meta: { authorize: [ 'ADMINISTRATOR' ] },
    },
    {
        path: '/lineof',
        name: 'LineOf',
        component: () => import('@views/LineOfInsurance.vue'),
        meta: { authorize: [ 'ADMINISTRATOR' ] },
    },
    {
        path: '/contact',
        name: 'Contact',
        component: () => import('@views/Contact.vue'),
        meta: { authorize: [ 'ADMINISTRATOR' ] },
    },
    {
        path: '/brand',
        name: 'Brand',
        component: () => import('@views/Brand.vue'),
        meta: { authorize: [ 'ADMINISTRATOR' ] },
    },
    {
        path: '/vehicletype',
        name: 'VehicleType',
        component: () => import('@views/VehicleType.vue'),
        meta: { authorize: [ 'ADMINISTRATOR' ] },
    },
    {
        path: '/holidays',
        name: 'Holidays',
        component: () => import('@views/Holidays'),
        meta: { authorize: [ 'ADMINISTRATOR' ] },
    },
    {
        path: '/escalation',
        name: 'escalation',
        component: () => import('@views/Escalation'),
        meta: { authorize: [ 'ADMINISTRATOR' ] },
    },
    {
        path: '/assignment',
        name: 'assignment',
        component: () => import('@views/Assignment'),
        meta: { authorize: [ 'ADMINISTRATOR' ] },
    },
    {
        path: '/files',
        name: 'filesAdministrator',
        component: () => import('@views/FilesAdministrator'),
        meta: { authorize: [ 'ADMINISTRATOR' ] },
    }
    ,
    {
        path: '/oldFiles',
        name: 'oldFiles',
        component: () => import('@views/OldFiles'),
        meta: { authorize: [ 'ADMINISTRATOR' ] },
    },

    {
        path: '/nodesNetwork',
        name: 'NodesNetwork',
        component: () => import('@views/NodesNetwork'),
        meta: { authorize: [ 'ADMINISTRATOR', 'RELATIONSHIP_NETWORK' ] },
    },
    

];

export default routes