import Vue from 'vue'
import VueRouter from 'vue-router'
import { authChecker } from '@helpers/authChecker'
// Configurador especifico rutas del modulo
import routes from '@routers/analystRoutes'

Vue.use(VueRouter);

const router = new VueRouter({ routes });

router.beforeEach(authChecker);

export default router
