import CryptoJS from 'crypto-js';

export default {
    key : CryptoJS.enc.Utf8.parse("@#$INIF20154&**+"), //${new Date().toISOString().substr(5, 5)}
    iv : CryptoJS.enc.Utf8.parse('@#$456324ASDFGRE'),

    // ttl time in minutes
    encrypt(info) {
        const encrypt = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(info), this.key, {
            keySize: 128 / 8,
            iv: this.iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });

        return encrypt.toString();
    },

    decrypt(decString) {
        const decrypted = CryptoJS.AES.decrypt(decString, this.key, {
            keySize: 128 / 8,
            iv: this.iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });

        return decrypted.toString(CryptoJS.enc.Utf8);
    },
}