import axios from 'axios'
import settings from '@/global'
import cache from '@helpers/localCache'
import { authHeader } from '@helpers/authHeader'
import crypto from '@helpers/crypto'

const url = `${process.env.VUE_APP_BASE_URL}/User`;
authHeader(axios);

export default {
    async all() {
        const key = settings.cache.users;
        let users = cache.get(key);
        
        if(!users) {
            const ret = await axios.get(`${url}/`);
            users = JSON.parse(crypto.decrypt(ret.data) );
            cache.set(key, users, 60);
        }

        return users;
    },

    async admin() {
        //console.log("Admin")
        const ret = await axios.get(`${url}/AdminUser`);
        //console.log(ret);
        return JSON.parse(crypto.decrypt(ret.data) );
    },

    async  getAll() {
        return axios.get(`${url}/`);
    },

    async save(data) {
        const key = settings.cache.users;
        cache.clear(key);
        
        const ret = await axios.post(`${url}/`, data);
        return ret.data;
    },

    async changePassword(data) {
        //authHeader(axios);
        const key = settings.cache.users;
        cache.clear(key);
        
        const ret = await axios.post(`${url}/ChangePassword`, data);
        return ret.data;
    },

    async forgotPassword(data) {
        return axios.post(`${url}/ForgotPassword`, data);
    },

    async login(data) {
        return axios.post(`${url}/Authenticate`, data);
    },

    async twoFactor(data) {
        return axios.post(`${url}/Validate2FA`, data);
    },

    async logout() {
        console.log("logout");
        var ret = await axios.get(`${url}/Logoff`);
        console.log(ret);

        if(ret.status == 200)
            localStorage.removeItem(settings.cache.user);
    },

    adminLogOut(data) {
        console.log("adminLogOut")
        console.log(data)
        return axios.post(`${url}/DelUserIp`, data);
    },

    current() {
        return JSON.parse(localStorage.getItem(settings.cache.user));
    },

    byId(id) {
        const key = settings.cache.users;
        let users = cache.get(key);

        return users ? users.find(u => u.id === id) : { name: "-" };
    },

    async rolesVersion() {
        authHeader(axios);
        const ret = await axios.get(`${url}/RolesVersion`);
        return new Date(ret.data).getTime();
    },

    async allRoles() {
        const key = settings.cache.roles;
          const ver = await this.rolesVersion();
          let roles = cache.get(key, ver);
    
          if(!roles) {
              const ret = await axios.get(`${url}/Roles`);
              roles = ret.data;
              cache.set(key, roles, 0, ver)
          }
    
          return roles;
      },


    async getRoles(id) {
        authHeader(axios);
        //const key = settings.cache.roles+id;
        //const ver = await this.rolesVersion(id);
        //let roles = cache.get(key, ver);

        //if(!roles) {
            const ret = await axios.get(`${url}/AllRolesFor/?id=${id}`);
            //roles = ret.data;
            return ret.data;
            //cache.set(key, roles, 0, ver)
        //}

        //return roles;
    },

    async saveRoles(data) {
        const key = settings.cache.roles;
        cache.clear(key);

        const ret = await axios.post(`${url}/Roles/`, data);
        return ret.data;
    },
};

/*
function onError(error) {
    if (error.response.status === 401)
        localStorage.removeItem("user");
}

function onError(error) {
    if (error.response.status === 401)
        localStorage.removeItem("user");

    console.error('Request Failed:', error.config);
    if (error.response) {
        console.error('Status:', error.response.status);
        console.error('Data:', error.response.data);
        console.error('Headers:', error.response.headers);
    } else {
        console.error('Error Message:', error.message);
    }

    return Promise.reject(error.response || error.message);
}
*/