var STEP = 1024;

export default function fileSize(size) {
  if (isNaN(size))
    size = 0;

  if (size < STEP)
    return size + ' Bytes';

  size /= STEP;

  if (size < STEP)
    return size.toFixed(2) + ' Kb';

  size /= STEP;

  if (size < STEP)
    return size.toFixed(2) + ' Mb';

  size /= STEP;

  if (size < STEP)
    return size.toFixed(2) + ' Gb';

  size /= STEP;

  return size.toFixed(2) + ' Tb';
}