export default {
    cache: {
        users: "SicoV2.1:users",
        user: "SicoV2.1:user",
        roles: "SicoV2.1:roles",

        departments: "SicoV2.1:departments",
        popular: "SicoV2.1:popularCities",
        cities: "SicoV2.1:cities",

        clients: "SicoV2.1:clients",

        contacts: "SicoV2.1:contacts",
        positions: "SicoV2.1:positions",

        coverages: "SicoV2.1:coverages",

        requests: "SicoV2.1:requests",
        task: "SicoV2.1:task:",

        services: "SicoV2.1:services",

        fileTypes: "SicoV2.1:fileTypes",

        providers: "SicoV2.1:providers",

        crimes: "SicoV2.1:crimes",

        identities: "SicoV2.1:identities",
        actortype: "SicoV2.1:actortype",

        vehicleType: "SicoV2.1:vehicleType",
        serviceType: "SicoV2.1:serviceType",
        relation: "SicoV2.1:relation",

        brand: "SicoV2.1:brand",
        postcalifications: "SicoV2.1:postcalifications",

        eps: "SicoV2.1:eps",
        primary: "SicoV2.1:primary",
        seriousness: "SicoV2.1:seriousness",
    },
};